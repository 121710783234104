import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Awards",
        tab3 = "Experience",
        tab4 = "Education & Certification";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        {/*<Tab>{tab2}</Tab>*/}
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Web front-end <span> - Development</span></a>
                                                    JavaScript, TypeScript, Jquery, CSS3, bootstrap, ReactJS, Vue.js, d3.js, Material UI, WebPack;
                                                </li>
                                                <li>
                                                    <a href="/service">Back-end<span> - Development</span></a>
                                                    NodeJS, Express, PHP, Laravel, Java, Spring;
                                                </li>
                                                <li>
                                                    <a href="/service">Hybrid app development <span> - Animation</span></a>
                                                    Ionic framework, PhoneGap, Cordova, React Native, Xamarin;
                                                </li>
                                                <li>
                                                    <a href="/service">Database <span> - Administration</span></a>
                                                    MariaDB/MySQL, MongoDB, Sql Server, PostgreSQL, SQLite;
                                                </li>
                                                <li>
                                                    <a href="/service">Cloud platforms <span> - Usages</span></a>
                                                    Firebase, AWS, Google App Engine.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    {/* <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Awwwards.com <span>- Winner</span></a> 2019 - 2020
                                               </li>
                                               <li>
                                                   <a href="/service">CSS Design Awards <span>- Winner</span></a> 2017 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Design nominees <span>- site of the day</span></a> 2013- 2014
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel> */}



                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Freelancer<span> - </span></a> 2015 - now
                                               </li>
                                               <li>
                                                   <a href="/service">Sr. Developer<span> - Yandex</span></a> 2014 - 2015
                                               </li>
                                               <li>
                                                   <a href="/service">Software Engineer<span> - Asteros </span></a> 2013 - 2014
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Master in Computer Science, 2012<span> - VSTU, Volgograd, Russia</span></a> 2009
                                               </li>
                                               <li>
                                                   <a href="/service">Bachalor in Computer Science, 2010<span> - VSTU, Volgograd, Russia</span></a> 2007
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;