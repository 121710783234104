import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'React Development',
        description: 'Strong experience in React project development. Development using Redux, Material-UI.'
    },
    {
        icon: <FiLayers />,
        title: 'NodeJS Development',
        description: 'Node.js development using Express, SQL/NoSQL (MongoDB, Redis, CouchDB) databases, WebSockets and Phantom.js'
    },
    {
        icon: <FiUsers />,
        title: 'Fast Prototyping',
        description: 'I can make functional prototype of your system for several days'
    },
    { 
        icon: <FiMonitor />,
        title: 'SPA App development',
        description: 'I\'m proficient in developing single-page applications of both types and will gladly help you choose the type of single page app that suits your business best'
    },
    {
        icon: <FiUsers />,
        title: 'UI/UX Development',
        description: 'Rich UI development using most modern frameworks and all known usability rules'
    },
    { 
        icon: <FiMonitor />,
        title: 'Mobile App Development',
        description: 'React Native development'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="#">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
